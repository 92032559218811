import { Directive, Input, TemplateRef, ViewContainerRef, effect } from '@angular/core';
import { RoleService } from './role-management.service';

@Directive({
    selector: '[appHasRole]',
    standalone: true,
})
export class HasRoleDirective {
    private hasRole: string;

    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private roleService: RoleService
    ) {
        effect(() => {
            this.roleService.userRoles();
            if (this.hasRole) {
                this.handleRole(this.hasRole);
            }
        });
    }

    @Input() set appHasRole(role: string) {
        this.handleRole(role);
    }

    private handleRole(role: string) {
        // Clear the view container to avoid duplications
        this.viewContainer.clear();

        // Check if the user has the role and then create the embedded view if valid
        if (role === 'CORPORATE' || role === 'SUBSCRIPTION') {
            if (this.roleService.supportedPlan(role)) {
                this.viewContainer.createEmbeddedView(this.templateRef);
            }
        } else if (this.roleService.hasRole(role)) {
            this.viewContainer.createEmbeddedView(this.templateRef);
        }

        this.hasRole = role;
    }
}

@Directive({
    selector: '[appHasAnyRole]',
    standalone: true,
})
export class HasAnyRoleDirective {
    private hasRoles: string[];

    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private roleService: RoleService
    ) {
        effect(() => {
            this.roleService.userRoles();
            if (this.hasRoles) {
                this.handleRole(this.hasRoles);
            }
        });
    }

    @Input() set appHasAnyRole(roles: string[]) {
        this.handleRole(roles);
    }

    private handleRole(roles: string[]) {
        // Clear the view container to avoid duplications
        this.viewContainer.clear();

        // Check if the user has the role and then create the embedded view if valid
        if (this.roleService.hasAnyRole(roles)) {
            this.viewContainer.createEmbeddedView(this.templateRef);
        }

        this.hasRoles = roles;
    }
}
